import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import urls from './urls';

const apiSecret: any = process.env.REACT_APP_FRUIT_PEOPLE_API_HEADER;

export const authService = async (
  email: string,
  password: string,
  setLoginError: Dispatch<SetStateAction<boolean | undefined>>,
  setLoginSuccess: Dispatch<SetStateAction<boolean | undefined>>
) => {
  const basicAuth = `Basic ${btoa(apiSecret)}`;
  const urlPost = `${urls.baseURL}oauth/token?username=${email}&password=${password}&grant_type=password`;
  setLoginError(false);

  await axios({
    method: 'post',
    url: urlPost,
    headers: {
      'content-type': 'application/json',
      Authorization: basicAuth
    }
  })
    .then((resp) => {
      const token = resp.data.access_token;
      sessionStorage.setItem('@fruit_people-token', token);
      return setLoginSuccess(true);
    })
    .catch(() => {
      return setLoginError(true);
    });
};
