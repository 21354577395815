/* eslint-disable react/require-default-props */
import React from 'react';

import { Container } from './styles';

interface MessageProps {
  error?: boolean;
  success?: boolean;
}

export const ValidationMessage: React.FC<MessageProps> = ({
  error,
  success,
  children
}) => {
  return (
    <Container error={error} success={success}>
      <i className="far fa-exclamation-circle" />
      {children}
    </Container>
  );
};
